import { BsPlus, BsSearch } from "react-icons/bs"
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Input } from "antd"
import axios from "axios"
import "@splidejs/splide/dist/css/splide.min.css";
import { useSelector } from "react-redux"
import { SplideSlide, Splide } from "@splidejs/react-splide"
import InvInfo from "./InvInfo"
import Chart from "react-apexcharts";


export default function Dashboard() {
    const userinfo = useSelector((state) => state.log_reducer.userinfo)
    const logined = useSelector((state) => state.log_reducer.logined)
    const navigate = useNavigate()
    const [data, setData] = useState({})
    const [invList, setInvList] = useState([])
    const [rewardList, setRewardList] = useState([])

    if (!logined) navigate("/")

    useEffect(() => {
        axios.post(global.backendserver + '/api/getNeedData', {
            payload: { id: userinfo.id }
        }).then((resp) => {
            setData({ ...resp.data[0] })
        })
        axios.post(global.backendserver + '/api/getInvestList', {
            payload: {
                user_id: userinfo.id,
                page: 1,
                limit: 20000
            }
        }).then((resp) => {
            setInvList(resp.data)
        })
        axios.post(global.backendserver + "/api/getRewardTokenList").then((resp) => {
            setRewardList(resp.data)
        })
    }, [])

    return <div className="flex flex-col mx-[10px] gap-4  w-[calc(100vw-20px)] ">
        <div className="flex flex-col py-6 justify-center items-center w-full">
            <img src={userinfo?.photo || '/default.webp'} alt="no image" className="bg-[#e5e5e5] w-[200px] md:w-[250px] 2xl:w-[300px] 2xl:h-[300px] h-[200px] md:h-[250px] rounded-[9999px]"></img>
            <span className="pt-0 font-bold text-xl md:text-3xl 2xl:text-5xl">{userinfo?.name}</span>
            <span className="pt-0 font-semibold text-gray-700 text-base md:text-xl 2xl:text-3xl">{userinfo?.company}</span>
        </div>
        <div className="flex flex-col font-bold text-2xl md:text-4xl 2xl:text-6xl px-10">
            <h4 className="font-bold">Funds Invested</h4>
            <span className=" font-bold text-4xl md:text-6xl 2xl:text-8xl px-10 bg-white h-[200px] flex justify-center items-center">{`$${userinfo.total ?? 0}`}</span>
        </div>
        <div className="flex flex-col  px-10">
            <h4 className="font-bold font-bold text-2xl md:text-4xl 2xl:text-6xl">Invested in Companies({invList.length})</h4>
            <Splide
                className="font-bold p-10 bg-white justify-center items-center"
                options={{
                    rewind: true,
                    gap: "1rem",
                    perPage: 4,
                    perMove: 1,
                    type: "loop",
                    breakpoints: {
                        1600: {
                            perPage: 3
                        },
                        1200: {
                            perPage: 2,
                        },
                        700: {
                            perPage: 1,
                        },
                    }
                }}
            >
                {invList.map((each) => (<SplideSlide key={each.id}>
                    <InvInfo key={each.id} data={each}></InvInfo>
                </SplideSlide>))}
            </Splide>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="flex mb-10 flex-col font-bold text-2xl md:text-4xl 2xl:text-6xl px-10">
                <h4 className="font-bold">Reward Pools</h4>
                <div className=" flex gap-4 py-10 font-semibold text-xl md:text-2xl 2xl:text-4xl px-10 bg-white  justify-center items-center h-[calc(200px+3.25em)] 2xl:h-[calc(500px+2.25em)]">
                    <Chart type="pie" options={{ colors: ["#55eebb", "#363636"], labels: ['Yours', 'Others'], chart: { width: '200', type: 'pie', height: '200' }, legend: { show: false }, }} width="200" height="200" colors={["#ffffff", "#aaaaaa"]} series={[data?.percent * 1, 1 - data?.percent]} >
                    </Chart>
                    <span className="font-bold">{data.percent * 100}%</span>
                </div>
            </div>

            <div className="flex mb-10 flex-col font-bold text-2xl md:text-4xl 2xl:text-6xl px-10">
                <h4 className="font-bold">Reward Tokens</h4>
                <div className="overflow-auto flex flex-row py-10 font-semibold text-xl md:text-2xl 2xl:text-4xl  bg-white  justify-cebter items-center">
                    <Splide
                        className=" p-10 bg-white h-[200px] 2xl:h-[500px] text-black w-full justify-center items-center"
                        options={{
                            rewind: true,
                            gap: "1rem",
                            perPage: 7,
                            perMove: 1,
                            type: "loop",
                            autoplay: true,
                            arrows: false,
                            interval:1000,
                            breakpoints: {
                                1800: {
                                    perPage: 6,
                                },
                                1560: {
                                    perPage: 5,
                                },
                                1320: {
                                    perPage: 4
                                },
                                1080: {
                                    perPage: 6
                                },
                                840: {
                                    perPage: 5,
                                },
                                600: {
                                    perPage: 4,
                                },
                            }
                        }}
                    >
                        {
                            rewardList.map((each) => (<SplideSlide key={each.id} >
                                <div className="w-full flex flex-col">
                                    <img src={each.logo} className="w-full flex rounded-[9999px] bg-[#e5e5e5] h-full cursor-pointer"></img>
                                    <span className="!text-black !visible justify-center flex">{each.symbol}</span>
                                </div>
                            </SplideSlide>))
                        }
                    </Splide>
                </div>
            </div>
        </div>

    </div >
}
