import React from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route, useLocation } from "react-router-dom"
import Home from './pages/Home'
import Label from './pages/Label'
import EditMember from './pages/EditMember'

function App() {
    const location = useLocation();
    const logined = useSelector((state) => state.log_reducer.logined)
    return (
        <div className="flex flex-col bg-[#e5e5e5] min-h-[100vh]">
            {logined && <Label />}
                <Routes location={location}>
                    <Route path="/" element={<Home />} />
                    <Route path="/editMember" element={<EditMember></EditMember>}></Route>
                </Routes>
            </div>
    )
}

export default React.memo(App)
