import { Modal, notification, Select } from 'antd'
import axios from 'axios'
import { useState, useEffect } from 'react'

export default function InvInfo(props) {
    const { data } = props
    const [currentPrice, setPrice] = useState("loading...")
    useEffect(()=>{
        if(data.cmc_id>0)
        axios.post(global.backendserver + "/api/getPriceById",{
            payload:{id : data.cmc_id}
        }).then((resp) => {
            setPrice(Number(Number(resp.data.price).toFixed(10)))
        })
    })
        return <div className="w-full flex flex-col justify-center items-center bg-[#e5e5e5] hover:bg-[#d4d4d4] cursor-pointer">
            <span className='font-normal w-full flex border-b border-gray-300 text-2xl xl:text-4xl 2xl:text-6xl py-6 justify-center items-align'>{data.investcompany}</span>
            <div className='w-full font-normal p-6 grid grid-cols-3 gap-2 text-base xl:text-xl 2xl:text-3xl' >
                <span className='flex flex-row justify-start text-gray-400 font-semibold py-4'>Token</span><span className='py-4 flex flex-row w-[200%] justify-end gap-2'><img className='w-[30px] h-[30px]' src={data.logo}></img>{data.name}({data.symbol})</span><span></span>
                <span className='flex flex-row justify-start text-gray-400 font-semibold'>CurrentPrice</span><span className='flex flex-row w-[200%] justify-end gap-2'>{data.cmc_id!=0?"$"+currentPrice:"NewToken"}</span><span></span>
                <span className='flex flex-row justify-start text-gray-400 font-semibold'>EntryPrice</span><span className='flex flex-row w-[200%] justify-end gap-2'>${Number(Number(data.amount / data.token_amount).toFixed(10))}</span><span></span>
                <span className='flex flex-row justify-start text-gray-400 font-semibold'>Invested</span><span className='flex flex-row w-[200%] justify-end gap-2'>${data.amount}</span><span></span>
            </div>
        </div>
    }