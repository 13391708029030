import { Modal, notification } from 'antd'
import axios from 'axios'
import md5 from 'md5'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from "../redux"
export default function Label() {
    const logined = useSelector((state) => state.log_reducer.logined)
    const userinfo = useSelector((state) => state.log_reducer.userinfo)
    const dispatch = useDispatch()
    const [visible, setModal] = useState(false)
    const [resetData, setResetData] = useState({})
    return <>
        {
            logined ? <h1 className="font-extrabold text-3xl md:text-5xl 2xl:text-7xl my-10 px-12 float-left">
                My DashBoard
                <div className=' absolute top-0 right-0 gap-0 flex flex-col md:flex-row'>
                <a onClick={() => {
                    setModal(true)
                }
                } className='flex font-semibold  text-gray-700 text-lg md:text-xl 2xl:text-3xl mt-10 px-4'>Reset Password</a>
                <a onClick={() => {
                    dispatch(actions.logOut())
                }
                } className='flex font-semibold text-gray-700 text-lg md:text-xl 2xl:text-3xl my-0 md:my-10 px-4'>Logout</a>
                </div>
                <Modal visible = {visible} title = "Reset Password" onCancel={()=>{
                    setModal(false)
                }} onOk = {()=>{
                    if(!resetData.pwd || !resetData.repwd||resetData.pwd == '' || resetData.repwd == ''){
                        notification.warn({
                            message : "Warning",
                            description:"Fill in all inputs.."
                        })
                        return
                    }
                    if(resetData.pwd != resetData.repwd){
                        notification.warn({
                            message : "Warning",
                            description:"Match inputs.."
                        })
                        return
                    }
                    axios.post(global.backendserver + "/api/resetPwd",{
                        payload : {
                            id : userinfo.id, password: md5(resetData.pwd)
                        }
                    }).then((res)=>{
                        notification.success({
                            message : "Success",
                            description:"Password Reset Success!!"
                        })
                        setModal(false)
                    })
                }}>
                    <div className='flex flex-col px-10 text-lg md:text-xl 2xl:text-3xl gap-6'>
                    <input type={"password"} onChange = {(e)=>{
                        setResetData((prev) => ({...prev, pwd:e.target.value}))
                    }} className = "bg-[#e5e5e5] p-2 px-4" placeholder='Input Password'/>
                    <input type={"password"} onChange = {(e)=>{
                        setResetData((prev) => ({...prev, repwd:e.target.value}))
                    }} className = "bg-[#e5e5e5] p-2 px-4" placeholder='Retype Password'/>
                    </div>
                </Modal>
            </h1> : <></>
        }
    </>
}